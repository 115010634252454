<template>
  <div
    class="main-dashboard"
    :class="collapsed ? 'collapsed' : 'non-collapsed'"
  >
    <nav-bar-dashboard></nav-bar-dashboard>
    <sidebar-dashboard></sidebar-dashboard>
    <b-container fluid>
      <div :class="loading ? 'show' : 'hide'">
        <loader
          object="#ff9633"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#cccccc"
          objectbg="#999793"
          opacity="80"
          name="dots"
        ></loader>
      </div>
      <div class="dash-title">
        <h1>DASHBOARD</h1>
      </div>
      <div
        class="bg-dash"
        :style="collapsed ? 'display: none' : 'display: inherit'"
      ></div>
      <div class="dash-cards-wrap">
        <div class="dash-card holerite col-md-4">
          <a
            href="http://45.174.220.242:8056/sipweb/trabalhador/login/login.xhtml"
            target="_blank"
          >
            <div class="text-wrap-dash">
              <span class="dash-card-title">Holerite Web</span>
              <span class="dash-sub-title">Clique e acesse</span>
            </div>
            <div class="bg-icon-holerite"></div>
          </a>
        </div>
        <div class="dash-card diario col-md-4">
          <router-link to="/diribas" target="_blank">
            <div class="text-wrap-dash">
              <span class="dash-card-title">Diário Oficial</span>
              <span class="dash-sub-title">Clique e acesse</span>
            </div>
            <div class="bg-icon-diario"></div>
          </router-link>
        </div>
        <div class="dash-card transparencia col-md-4">
          <a href="http://45.174.220.242:8079/transparencia" target="_blank">
            <div class="text-wrap-dash">
              <span class="dash-card-title">Portal da Transparência</span>
              <span class="dash-sub-title">Clique e acesse</span>
            </div>
            <div class="bg-icon-transparencia"></div>
          </a>
        </div>
      </div>
      <div v-if="user.multiPublication" class="dash-switch">
        <b-form-checkbox
          @change="getDiribas"
          v-model="diribasMode"
          name="check-button"
          switch
        >
          Modo Diário Oficial
        </b-form-checkbox>
      </div>
      <div v-if="!diribasMode" class="dash-last-label">
        <span>
          Últimas publicações
          <small> (Clique nas células para vizualizar)</small></span
        >
        <b-button
          v-if="user.role.name != 'Observador'"
          @click="openUrl"
          variant="success"
          ><i class="fas fa-plus"></i>Nova publicação</b-button
        >
      </div>
      <div v-if="!diribasMode" class="dash-last-pub">
        <vue-good-table
          class="table-bordered table-hover"
          :columns="columns"
          :rows="publications"
          @on-cell-click="onCellClick"
          :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            nextLabel: 'Próximo',
            prevLabel: 'Anterior',
            rowsPerPageLabel: 'Linhas por página',
            ofLabel: 'de',
            pageLabel: 'página',
          }"
        >
          <template class="dash-actions" slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'actions'">
              <b-button
                title="Editar Publicação"
                v-if="editPerm"
                class="edit-button"
                variant="primary"
                @click="goEdit(props.row.id)"
                :style="collapsed ? 'margin-right: 5px;' : 'margin-right: 0;'"
              >
                <i class="far fa-edit"></i>
              </b-button>
              <b-button
                title="Remover Publicação"
                v-if="delPerm"
                @click="showModal(props.row)"
                variant="danger"
              >
                <i class="far fa-trash-alt"></i>
              </b-button>
              <b-button
                title="Visualizar Publicação"
                v-if="!delPerm && !editPerm"
                @click="onCellClick(props, true)"
                variant="secondary"
              >
                <i class="far fa-eye"></i>
              </b-button>
              <b-modal
                ref="my-modal"
                cancel-title="Cancelar"
                ok-title="Confirmar"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                title="Deseja remover a seguinte publicação?"
                footer-bg-variant="light"
                @ok="removePubli"
                @cancel="removeDeleteId"
              >
                <div class="modal-text">
                  <span>{{ modalText }}</span>
                </div>
              </b-modal>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>

      <div v-if="diribasMode" class="dash-last-label">
        <span> Publicações em aberto (Diário Oficial)</span>
        <div class="diribas-buttons">
          <b-button @click="openUrlDiribas(false)" variant="success"
            ><i class="fas fa-plus"></i>Nova publicação - DIRIBAS</b-button
          >
          <b-button
            v-if="allowSupplement"
            @click="openUrlDiribas(true)"
            variant="primary"
            ><i class="fas fa-plus"></i>Suplemento - DIRIBAS</b-button
          >
          <b-button
            v-if="allowSupplement && supplement"
            @click="checkSupplement"
            variant="info"
            ><i class="fas fa-paper-plane"></i>Publicar Suplemento</b-button
          >
          <b-button
            v-if="allowSupplement && supplement"
            @click="removeSupplementConfirm"
            variant="danger"
            ><i class="fas fa-ban"></i>Cancelar Suplemento</b-button
          >
          <b-button
            v-if="isAdmin"
            @click="showGenerateModal(true)"
            variant="warning"
            ><i class="fas fa-exclamation-triangle"></i>Publicar DIRIBAS</b-button
          >
        </div>
      </div>
      <div v-if="diribasMode" class="dash-last-pub">
        <vue-good-table
          class="table-bordered table-hover"
          :columns="columnsDiribas"
          :rows="diribasPublications"
          :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            nextLabel: 'Próximo',
            prevLabel: 'Anterior',
            rowsPerPageLabel: 'Linhas por página',
            ofLabel: 'de',
            pageLabel: 'página',
          }"
        >
          <template class="dash-actions" slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'actions'">
              <b-button
                title="Editar Publicação"
                class="edit-button"
                variant="primary"
                @click="goEditDiribas(props.row.id)"
                :style="collapsed ? 'margin-right: 5px;' : 'margin-right: 0;'"
              >
                <i class="far fa-edit"></i>
              </b-button>
              <b-button
                title="Remover Publicação"
                @click="showModal(props.row)"
                variant="danger"
              >
                <i class="far fa-trash-alt"></i>
              </b-button>
              <b-button
                style="margin-left: 5px"
                title="Visualizar Publicação"
                @click="previewDiribas(props)"
                variant="secondary"
              >
                <i class="far fa-eye"></i>
              </b-button>
              <b-modal
                ref="my-modal"
                cancel-title="Cancelar"
                ok-title="Confirmar"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                title="Deseja remover a seguinte publicação?"
                footer-bg-variant="light"
                @ok="removePubli"
                @cancel="removeDeleteId"
              >
                <div class="modal-text">
                  <span>{{ modalText }}</span>
                </div>
              </b-modal>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <b-modal
        ref="response-modal"
        footer-bg-variant="light"
        ok-only
        :title="confirmMessage"
      >
      </b-modal>
      <b-modal
        ref="response-supplement-modal"
        footer-bg-variant="light"
        ok-only
        @ok="refreshPage"
        :title="confirmMessage"
      >
      </b-modal>
      <b-modal
        scrollable
        size="xl"
        ref="preview-modal"
        footer-bg-variant="light"
        :hide-footer="true"
        title="Pré-visualização"
      >
        <render-preview :publication="previewPublication"></render-preview>
      </b-modal>
      <b-modal
        ref="supplement-remove-modal"
        cancel-title="Manter suplmento"
        ok-title="Sim, remover"
        ok-variant="outline-danger"
        cancel-variant="primary"
        title="Confirme a operação"
        footer-bg-variant="light"
        @ok="removeSupplement()"
      >
        <div class="modal-text">
          <span>Deseja cancelar o suplmento?</span>
        </div>
      </b-modal>
      <b-modal
        ref="supplement-removed-modal"
        footer-bg-variant="light"
        ok-only
        title="Suplemento Removido"
        @ok="refreshPage"
      >
      </b-modal>
      <b-modal
        ref="feedback-modal"
        footer-bg-variant="light"
        ok-only
        :title="modalTitle"
      >
        {{ modalText }}
      </b-modal>
      <b-modal
        ref="confirm-supplement"
        ok-title="Publicar"
        cancel-title="Cancelar"
        ok-variant="primary"
        cancel-variant="outline-danger"
        title="Confirme a operação"
        footer-bg-variant="light"
        @ok="storeSupplement()"
      >
        <div class="modal-text">
          <span>Deseja publicar o suplmento?</span>
        </div>
      </b-modal>
      <b-modal
        ref="generate-modal"
        ok-variant="primary"
        cancel-title="Cancelar"
        cancel-variant="outline-danger"
        title="Confirme a operação"
        footer-bg-variant="light"
        @ok="generateDiribas()"
      >
        <div class="modal-text">
          <span>Gerar diário oficial?</span>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import SidebarDashboard from "../../components/SidebarDashboard";
import PublicationService from "../../services/PublicationService";
import UserService from "../../services/UserService";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
import NavBarDashboard from "../../components/NavBarDashboard.vue";
import DiribasService from "../../services/DiribasService";
import RenderPreview from "../../components/RenderPreview.vue";

export default {
  data() {
    return {
      collapsed: true,
      publications: [],
      modalText: "",
      modalTitle: "",
      idToDelete: null,
      confirmMessage: "",
      user: "",
      delPerm: false,
      editPerm: false,
      allowSupplement: false,
      supplement: "",
      columns: [
        {
          field: "date",
          label: "Data",
          dateOutputFormat: "DD-MM-YYYY",
          dateInputFormat: "DD-MM-YYYY",
          filterOptions: {
            enabled: true,
            placeholder: "Busque pela data da publicação",
            filterFn(data, filterString) {
              return moment(data)
                .format("DD/MM/YYYY HH:mm")
                .includes(filterString);
            },
          },
          formatFn(date) {
            return moment(date).format("DD/MM/YYYY HH:mm");
          },
        },
        {
          field: "title",
          label: "Título",
          filterOptions: {
            enabled: true,
            placeholder: "Busque pelo título da publicação",
          },
        },
        {
          field: "actions",
          label: "Ações",
          sortable: false,
          hidden: false,
        },
      ],
      columnsDiribas: [
        {
          field: "title",
          label: "Título",
          filterOptions: {
            enabled: true,
            placeholder: "Busque pelo título da publicação",
          },
        },
        {
          field: "supplement",
          label: "Suplemento",
          formatFn(supplement) {
            return supplement ? "Sim" : "Não";
          },
        },
        {
          field: "department",
          label: "Secretaria",
          formatFn(department, row) {
            return row.sub_department
              ? row.sub_department.name
              : department.name;
          },
        },
        {
          field: "actions",
          label: "Ações",
          sortable: false,
          hidden: false,
        },
      ],
      diribasMode: false,
      diribasPublications: [],
      isAdmin: false,
      previewPublication: {
        title: "",
        text: "",
        department: "",
        sub_department: "",
        sub_department_id: null,
        user_id: "",
      },
      loading: false,
    };
  },
  created() {
    if (localStorage.getItem("refresh") == "0") {
      localStorage.setItem("refresh", "1");
      this.$router.go();
    }
  },
  mounted() {
    this.$root.$on("collapse", this.collapse);

    UserService.listById(localStorage.getItem("userId")).then((res) => {
      this.user = res.data;
      this.delPerm = res.data.role.delete;
      this.editPerm = res.data.role.edit;
      PublicationService.listPaginate({
        userId:
          res.data.role.name == "Admin" ? null : localStorage.getItem("userId"),
      }).then((resPub) => {
        this.publications = resPub.data.publications;
      });
      this.isAdmin = this.user.role.name == "Admin";
      this.allowSupplement =
        this.user.role.name == "Admin" ||
        this.user.role.name == "Administrador";
    });
    DiribasService.listSupplement().then((res) => {
      this.supplement = res.data;
    });
  },
  components: {
    SidebarDashboard,
    VueGoodTable,
    NavBarDashboard,
    RenderPreview,
  },
  methods: {
    collapse() {
      this.collapsed = !this.collapsed;
    },
    goEdit(publicationId) {
      this.$router.push({
        name: "publication-edit",
        params: { id: publicationId, title: " " },
      });
    },
    onCellClick(props, view = false) {
      if (props.column.label != "Ações" || view) {
        let title = props.row.title
          .replace(/[\s$-/:-?{-~!"^_`[\]]/g, "-")
          .toLowerCase();
        title = title
          .normalize("NFD")
          .replace(/[\u0300-\u036f\u00aa\u00ba]/g, "");
        let route = this.$router.resolve({
          name: "publication-view",
          params: {
            id: props.row.id,
            title,
          },
        });
        window.open(route.href, "_blank");
      }
    },
    previewDiribas(props) {
      this.previewPublication = props.row;
      this.$refs["preview-modal"].show();
    },
    openUrl() {
      this.$router.push({
        name: "publication-edit",
        params: { id: "nova", title: " " },
      });
    },
    openUrlDiribas(supplement) {
      if (supplement) {
        this.$router.push({
          name: "supplement",
        });
      } else {
        this.$router.push({
          name: "newdiribasupload",
        });
      }
    },
    showModal(row) {
      this.modalText = row.title;
      this.idToDelete = row.id;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    removePubli() {
      this.hideModal();
      if (!this.diribasMode) {
        PublicationService.delete(this.idToDelete)
          .then(() => {
            this.publications.splice(
              this.publications.findIndex(({ id }) => id == this.idToDelete),
              1
            );
            this.idToDelete = null;
            this.confirmMessage = "Publicação removida";
            this.$refs["response-modal"].show();
          })
          .catch(() => {
            this.confirmMessage = "Um erro ocorreu ao remover a publicação";
          });
      } else {
        DiribasService.delete(this.idToDelete)
          .then(() => {
            this.diribasPublications.splice(
              this.diribasPublications.findIndex(
                ({ id }) => id == this.idToDelete
              ),
              1
            );
            this.idToDelete = null;
            this.confirmMessage = "Publicação removida";
            this.$refs["response-modal"].show();
          })
          .catch(() => {
            this.confirmMessage = "Um erro ocorreu ao remover a publicação";
          });
      }
    },
    removeDeleteId() {
      this.idToDelete = null;
    },
    getDiribas() {
      if (this.diribasPublications.length > 0) return;

      DiribasService.listPaginatePublications({
        userId: this.user.role.name == "Admin" ? null : this.user.id,
      }).then((res) => {
        this.diribasPublications = res.data.publications;
      });
    },
    removeSupplementConfirm() {
      this.$refs["supplement-remove-modal"].show();
    },
    removeSupplement() {
      DiribasService.deletePost(this.supplement.id).then(() => {
        this.$refs["supplement-removed-modal"].show();
      });
    },
    refreshPage() {
      this.$router.go();
    },
    checkSupplement() {
      this.loading = true;
      DiribasService.checkSupplement(this.supplement.id).then((res) => {
        if (res.data) {
          this.confirmSupplement();
        } else {
          this.modalTitle = "Não é possível publicar o suplemento";
          this.modalText =
            "Nenhuma publicação foi encontrada, para publicar o suplemento primeiro envie uma publicação";
          this.loading = false;
          this.$refs["feedback-modal"].show();
        }
      });
    },
    confirmSupplement() {
      this.loading = false;
      this.$refs["confirm-supplement"].show();
    },
    storeSupplement() {
      this.loading = true;
      DiribasService.publishSupplement().then(() => {
        this.loading = false;
        this.confirmMessage = "Suplemento Publicado";
        this.$refs["response-supplement-modal"].show();
      });
    },
    goEditDiribas(id) {
      this.$router.push({
        name: "newdiribasupload",
        params: { id },
      });
    },
    showGenerateModal() {
      this.$refs["generate-modal"].show();
    },
    generateDiribas() {
      this.loading = true;
      DiribasService.generateDiribas()
        .then(() => {
          this.modalTitle = "Diário oficial publicado";
          this.modalText = "DIRIBAS gerado com sucesso";
          this.loading = false;
          this.$refs["feedback-modal"].show();
        })
        .catch(() => {
          this.modalTitle = "Ops, ocorreu um problema";
          this.modalText = "Diário oficial não pode ser gerado";
          this.loading = false;
          this.$refs["feedback-modal"].show();
        });
    },
  },
};
</script>
<style lang="scss">
.collapsed {
  padding-left: 50px;
  @media (max-width: 1400px) {
    padding-left: 0;
  }
}
.non-collapsed {
  padding-left: 350px;
  @media (max-width: 1400px) {
    padding-left: 0;
  }
}
.hide {
  display: none;
}
.show {
  display: block;
}
.main-dashboard {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  // display: flex;
  font-family: Raleway;
  transition: 0.3s ease;
  margin-right: 80px;
  margin-left: 80px;
  z-index: 1;
  @media (max-width: 700px) {
    margin-right: 0;
    margin-left: 60px;
  }

  .dash-title {
    margin-top: 100px;
    margin-left: 0;
    text-align: initial;
    font-family: Ubuntu;
    font-weight: bold;
    color: #707070;
    @media (max-width: 700px) {
      font-size: 2rem;
    }
  }
  .bg-dash {
    @media (max-width: 1400px) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      opacity: 0.6;
      background-color: #343a40;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
  .dash-cards-wrap {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
    @media (max-width: 770px) {
      display: grid;
      justify-content: inherit;
    }
    .dash-card {
      height: 230px;
      color: white;
      text-align: initial;
      border-radius: 5px;
      max-width: 32.5%;
      @media (max-width: 770px) {
        max-width: 100%;
        margin-bottom: 10px;
        height: 200px;
      }
      a {
        text-decoration: none;
        color: white;
      }
      .text-wrap-dash {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        .dash-card-title {
          font-size: 40px;
          font-weight: bold;
          @media (max-width: 1100px) {
            font-size: 30px;
          }
          @media (max-width: 900px) {
            font-size: 25px;
          }
          @media (max-width: 770px) {
            font-size: 30px;
          }
        }
        .dash-sub-title {
          font-size: 30px;
          font-weight: bold;
          @media (max-width: 1100px) {
            font-size: 20px;
          }
          @media (max-width: 900px) {
            font-size: 15px;
          }
          @media (max-width: 770px) {
            font-size: 20px;
          }
        }
      }
    }
    .holerite {
      background-image: linear-gradient(to right, #1749ee, #00087b);
      .bg-icon-holerite {
        position: relative;
      }
      .bg-icon-holerite:after {
        position: absolute;
        font-family: "Font Awesome 5 Free"; /* Ou "Font Awesome 6 Free" */
        font-weight: 900; /* Necessário para ícones sólidos */
        font-size: 110px;
        color: white;
        bottom: 0;
        right: 0;
        content: "\f543";
        opacity: 0.5;
      }
    }
    .diario {
      background-image: linear-gradient(to right, #fc5959, #800000);
      .bg-icon-diario {
        position: relative;
      }
      .bg-icon-diario:after {
        position: absolute;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 110px;
      color: white;
      bottom: 0;
      right: 0;
      content: "\f1ea";
      opacity: 0.5;
      }
    }
    .transparencia {
      background-image: linear-gradient(to right, #2ef850, #046e00);
      .bg-icon-transparencia {
        position: relative;
      }
      .bg-icon-transparencia:after {
        position: absolute;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 110px;
      color: white;
      bottom: 0;
      right: 0;
      content: "\f688";
      opacity: 0.5;
      }
    }
  }
  .dash-switch {
    margin-top: 10px;
    text-align: initial;
  }
  .dash-last-label {
    font-family: Raleway;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: bold;
    }
    button {
      font-weight: bold;
      i {
        margin-right: 5px;
      }
    }
    .diribas-buttons {
      display: flex;
      button {
        margin-left: 5px;
      }
    }
  }
  .dash-last-pub {
    margin-top: 10px;
    margin-bottom: 50px;
    font-weight: bold;
    font-family: Montserrat;
    .edit-button {
      max-width: 40px;
    }
  }
  .modal-dialog {
    max-width: 1000px;
  }
}
</style>