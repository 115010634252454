<template>
  <div class="nav-dash-container">
    <div class="dash-logo-container">
      <router-link to="/">
        <img class="logo-dash" src="@/assets/brasao.png" />
      </router-link>
    </div>
    <div class="dash-user">
      <span>Olá, {{ userName }}</span>
      <i title="Sair" @click="logout" style="margin-left: 5px" class="fas fa-sign-out-alt"></i>
    </div>
  </div>
</template>
<script>
import AuthenticationService from "../services/AuthenticationService";
export default {
  name: "NavBarDashboard",
  data() {
    return {
      userName: "",
    };
  },
  created() {
    this.userName = localStorage.getItem("userName");
  },
  methods: {
      async logout() {
      if (this.$cookies.get("remember_id"))
        await AuthenticationService.removeSession(
          localStorage.getItem("userId")
        );
      this.$cookies.remove("remember_id");
      this.$cookies.remove("remember_name");
      localStorage.removeItem("userName");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      this.userName = null;
      this.$router.go();
    },
  }
};
</script>
<style lang="scss">
.nav-dash-container {
  background-color: #204991;
  width: 100%;
  position: fixed;
  top: 0;
  height: 80px;
  bottom: 0;
  left: 0;
  border-bottom: 5px solid #efd02f;
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-weight: bold;
  justify-content: space-between;
  z-index: 2;
  .logo-dash {
    height: 60px;
    padding-left: 30px;
    @media (max-width: 1200px) {
      height: 40px;
      padding-left: unset;
    }
  }
  .dash-user {
    display: flex;
    align-items: center;
    color: white;
    margin-right: 10px;
    span {
        font-size: 20px;
        margin-right: 5px;
    }
    i {
        font-size: 25px;
        margin-right: 10px;
        &:hover {
            cursor: pointer;
        }
    }
    @media(max-width: 700px) {
      span {
        font-size: 15px;
      }
      i {
        font-size: 22px;
    }
    }
  }
}
</style>